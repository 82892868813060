import { SearchState } from "react-instantsearch-core"
import { IntlShape } from "react-intl"

import { AllCategories } from "~/hooks/useCategories"
import {
  addHashToUrl,
  getCategoriesTreePart,
  getDietPart,
  getDifficultyPart,
  getIngredientsPart,
  getTypePart,
  getVerticalPart,
  parseHash,
} from "~/templates/Category/utils/urlUtils"
import { getCategoriesFacetsBySlug } from "~/utils/createPages/categoriesUtils"

const createCategoryURL = (
  state: SearchState,
  CATEGORIES: AllCategories,
  baseSlug: string
) => {
  let url = `/c/`
  const hashPart = []

  const { mainPart } = getCategoriesTreePart(state, CATEGORIES, true)

  if (mainPart) {
    url += `${mainPart}/`
  } else {
    url += `${baseSlug}/`
  }

  const { hashPart: verticalPart } = getVerticalPart(state)
  hashPart.push(verticalPart)

  const { mainPart: ingredientsMainPart, hashPart: ingredientsPart } =
    getIngredientsPart(state, true)
  if (ingredientsMainPart) {
    url += `${ingredientsMainPart}`
  }
  hashPart.push(ingredientsPart)

  const { hashPart: typePart } = getTypePart(state)
  hashPart.push(typePart)

  const { hashPart: durationPart } = getDifficultyPart(state)
  hashPart.push(durationPart)

  const { hashPart: dietPart } = getDietPart(state)
  hashPart.push(dietPart)

  return url ? `${addHashToUrl(url, hashPart)}` : ""
}

function searchStateToCategoryUrl(
  searchState: SearchState,
  CATEGORIES: AllCategories,
  intl: IntlShape
) {
  const baseSlug = intl.formatMessage({ id: "path:recipes" })
  let url = `/c/${baseSlug}/`

  if (searchState) {
    url = `${createCategoryURL(searchState, CATEGORIES, baseSlug)}`
  }

  return url
}

const categoryUrlToSearchState = (
  search: string,
  pathname: string,
  hash: string,
  CATEGORIES: AllCategories
) => {
  let searchState: any = {}

  const hierarchicalMenu: any = {}

  const pathnamePart = pathname.split("/")

  //In the fallback route pathnamePart = ["", locale, "c", category, ingredient, ""] vs [cateogry, ingredient] for the SSG route so we remove the first 3 elements in the fallback route
  if (pathnamePart[0] === "") {
    pathnamePart.splice(0, 3)
  }

  const categories = getCategoriesFacetsBySlug(pathnamePart[0], CATEGORIES)
  if (categories.length > 0) {
    hierarchicalMenu["categories_tree.lvl0"] = categories.reverse().join(" > ")
  }
  if (hierarchicalMenu["categories_tree.lvl0"]) {
    searchState = {
      ...searchState,
      hierarchicalMenu,
    }
  }

  if (pathnamePart[1]) {
    searchState = {
      ...searchState,
      refinementList: {
        ...searchState.refinementList,
        "star_ingredients.slug": [pathnamePart[1]],
      },
    }
  }

  if (hash) {
    searchState = parseHash(hash, CATEGORIES, searchState)
  }

  return searchState
}

function lowerCaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export {
  categoryUrlToSearchState,
  createCategoryURL,
  lowerCaseFirstLetter,
  searchStateToCategoryUrl,
}
