import { capitalizeFirstLetter } from "./string"

export const categoryIsTipsAndTricks = (
  categorySlug: string
): { isTrue: boolean; isSingular: boolean } => {
  return {
    isTrue: categorySlug.startsWith("astuce"), // « Les Astuces blablabla Chefclub ! » instead of « Les Recettes d'Astuces blablabla Chefclub ! »
    isSingular: categorySlug === "astuce", // Forcing plural if category value is "astuce" — « Les Astuces Chefclub ! » instead of « Les Astuce Chefclub ! »
  }
}

// « Les Recettes Sucrées/Salées Chefclub ! » instead of « Les Recettes de Sucré/Salé Chefclub ! »
export const categoryIsAdjective = (categorySlug: string): boolean => {
  return ["sucre", "sale"].includes(categorySlug)
}

// Handling special format of « Les Recettes au(x) Chocolat/Œufs... Chefclub ! » instead of « Les Recettes d'/de Chocolat/Œufs... Chefclub ! »
export const categoryStartsWithAu = (
  categorySlug: string
): { isTrue: boolean; isPlural: boolean } => {
  return {
    isTrue: [
      "chocolat",
      "camembert",
      "chevre",
      "riz",
      "saumon",
      "thon",
      "tofu",
    ].includes(categorySlug),
    isPlural: ["œufs", "legumes"].includes(categorySlug),
  }
}

// Handling special format of « Les Recettes à la crevette/volaille... Chefclub ! » instead of « Les Recettes d'/de crevette/volaille... Chefclub ! »
export const categoryStartsWithA = (categorySlug: string): boolean => {
  return [
    "crevette",
    "volaille",
    "pate-a-tartiner",
    "viandes-et-œufs",
  ].includes(categorySlug)
}

// Handling very peculiar case to display « Les Recettes à la Viande Et aux Œufs Chefclub ! » instead of « Les Recettes à la Viandes et Œufs Chefclub ! »
const specialWording = { viandeEtOeufs: "Viande Et aux Œufs" }

// Capitalizes category names if they aren't "hidden" and reshape categories with above specific names
export const getCapitalizedCategory = (
  categorySlug: string,
  categoryValue: string
): string => {
  return categorySlug === "viandes-et-œufs"
    ? specialWording.viandeEtOeufs
    : categoryValue !== "hidden"
    ? capitalizeFirstLetter(categoryValue)
    : categoryValue
}
