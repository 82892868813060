import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import {
  formatBannerToDisplay,
  PrismicBanners,
} from "../../components/Banner/utils"
import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import { hit } from "./types"
import useSiteMetadata from "~/hooks/useSiteMetadata"
import FilterPage from "~/templates/Category/FilterPage"
import {
  categoryUrlToSearchState,
  createCategoryURL,
  searchStateToCategoryUrl,
} from "~/templates/Category/utils/categoriesUrlUtils"
import {
  categoryIsAdjective,
  categoryIsTipsAndTricks,
  categoryStartsWithA,
  categoryStartsWithAu,
  getCapitalizedCategory,
} from "~/utils/categoryDictionnaries"
import { getCategoryById } from "~/utils/createPages/categoriesUtils"
import { capitalizeFirstLetter, isFirstLetterVowel } from "~/utils/string"

export type PageContextCategory = {
  pageH1: string
  pageTitle: string
  nbHits: number
  searchState: string
  currentLocale: {
    id: string
    name: string
  }
  hits: hit[]
  defaultLocale: {
    id: string
    name: string
    prismicCode: string
  }
  hierarchicalFacets: any
  verticalRefinementList: any
  starIngredientsRefinementList: any
  slug: string
  description: string
  descriptionSEO: string
}

interface PropTypes extends TemplateDefaultPropTypes {
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextCategory
  data: { prismicBanner: PrismicBanners | null }
}

function CategoryPage({
  location,
  pageContext,
  path,
  navigate,
  data,
}: PropTypes) {
  const intl = useIntl()

  const { siteUrl } = useSiteMetadata()

  const refreshTitle = (
    searchState: any,
    CATEGORIES: any,
    isSSG: boolean,
    nbHits: number
  ): {
    pageH1: string
    pageTitle: string
    description?: string
    descriptionSEO: string
  } => {
    let category: string | undefined
    let ingredient: string | undefined
    if (isSSG) {
      category = searchState?.hierarchicalFacetsRefinements?.categories_tree
        ?.length
        ? searchState.hierarchicalFacetsRefinements.categories_tree[0]
        : null

      ingredient =
        searchState.facetsRefinements &&
        searchState.facetsRefinements["star_ingredients.slug"]?.length
          ? searchState.facetsRefinements["star_ingredients.slug"][0]
          : null
    } else {
      category =
        searchState.hierarchicalMenu &&
        searchState.hierarchicalMenu["categories_tree.lvl0"]
          ? searchState.hierarchicalMenu["categories_tree.lvl0"]
              .split(" > ")
              .slice(-1)[0]
          : null

      ingredient =
        searchState.refinementList &&
        searchState.refinementList["star_ingredients.slug"]?.length
          ? searchState.refinementList["star_ingredients.slug"][0].replace(
              /-/g,
              " "
            )
          : null
    }
    const categoryData = category
      ? getCategoryById(category.split(" > ").slice(-1)[0], CATEGORIES)
      : null

    let description = category
      ? intl.formatMessage(
          { id: "category/text:custom-description" },
          { category: category }
        )
      : intl.formatMessage({
          id: "category/text:default-description",
        })

    const categoryValue = categoryData?.name ?? "hidden"
    const categorySlug = categoryData?.slug ?? ""
    const ingredientValue = ingredient
      ? capitalizeFirstLetter(ingredient)
      : "hidden"

    const countValue = "-1" // forced to stop showing counts in headings and metas

    const isCategoryFirstLetterVowel =
      intl.locale === "fr" && isFirstLetterVowel(categoryValue)

    // TODO: Handle grammatical cases in an automated way if/when a new API field would be added

    const [isTipsAndTricks, isAdjective, startsWithAu, startsWithA] = [
      intl.locale === "fr" && categoryIsTipsAndTricks(categorySlug), // Handling special format of tips & tricks
      intl.locale === "fr" && categoryIsAdjective(categorySlug), // Handling special format of "sucré" and "salé" categories
      intl.locale === "fr" && categoryStartsWithAu(categorySlug), // Handling categories that should start with "au(x)"
      intl.locale === "fr" && categoryStartsWithA(categorySlug), // Handling categories that should start with "à la" / "à l'"
    ]

    const startsWith =
      startsWithAu && startsWithAu.isTrue
        ? "au"
        : startsWithAu && startsWithAu.isPlural
        ? "aux"
        : startsWithA
        ? "a"
        : null

    const capitalizedCategory = getCapitalizedCategory(
      categorySlug,
      categoryValue
    )

    const pageH1 = intl.formatMessage(
      {
        id: "category/text:h1",
      },
      {
        category: capitalizedCategory,
        ingredient: ingredientValue,
        count: countValue,
        categoryFirstLetterIsVowel: isCategoryFirstLetterVowel,
        isTipsAndTricks: isTipsAndTricks && isTipsAndTricks.isTrue,
        isSingularTip: isTipsAndTricks && isTipsAndTricks.isSingular,
        isAdjective,
        startsWith,
      }
    )

    const pageTitle = intl.formatMessage(
      {
        id: "category/text:title",
      },
      {
        category: capitalizedCategory,
        ingredient: ingredientValue,
        count: countValue,
        categoryFirstLetterIsVowel: isCategoryFirstLetterVowel,
        isTipsAndTricks: isTipsAndTricks && isTipsAndTricks.isTrue,
        isSingularTip: isTipsAndTricks && isTipsAndTricks.isSingular,
        isAdjective,
        startsWith,
      }
    )

    const descriptionSEO = intl.formatMessage(
      {
        id: "category/text:seo-description",
      },
      {
        category: capitalizedCategory,
        ingredient: ingredientValue,
        count: countValue,
        categoryFirstLetterIsVowel: isCategoryFirstLetterVowel,
        isTipsAndTricks: isTipsAndTricks && isTipsAndTricks.isTrue,
        isSingularTip: isTipsAndTricks && isTipsAndTricks.isSingular,
        isAdjective,
        startsWith,
      }
    )

    description = categoryData?.description ?? description

    return {
      pageH1,
      pageTitle,
      descriptionSEO,
      description,
    }
  }

  const bannerToDisplay = data.prismicBanner
    ? formatBannerToDisplay(data.prismicBanner)
    : null
  return (
    <>
      <FilterPage
        pageContext={pageContext}
        createURL={createCategoryURL}
        urlToSearchState={categoryUrlToSearchState}
        searchStateToUrl={searchStateToCategoryUrl}
        path={path}
        navigate={navigate}
        location={location}
        refreshTitle={refreshTitle}
        baseSlug={intl.formatMessage({ id: "path:recipes" })}
        bannerToDisplay={bannerToDisplay}
      />
    </>
  )
}
export default withTemplate(CategoryPage)

//You can find the fragment definition in src/components/Banner/Banner.tsx
export const query = graphql`
  query CategoryBanners($prismicLanguage: String) {
    prismicBanner(
      data: { banner_type: { eq: "filtered_recipes_page" } }
      lang: { eq: $prismicLanguage }
    ) {
      ...Banner
    }
  }
`
